import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useDataProvider } from 'react-admin';
import { useParams, useHistory } from 'react-router-dom';

import { Grid, makeStyles } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { Form } from 'react-final-form';
import { FormApi } from 'final-form';

import SubmitButton from 'components/buttons/SubmitButton';
import Radio from 'components/form/Radio';
import RadioGroup from 'components/form/RadioGroup';

import PageHeader from './components/PageHeader';
import SectionCard from './components/SectionCard';
import SummarySection from './components/SummarySection';
import LostItemSection from './components/LostItemSection';

import { ClaimReason, Item, ItemPhotoType } from './types';
import PackagePhotoSection from './components/PackagePhotosSection';
import DamagedItemSection from './components/DamagedItemSection';
import useCustomNotify from 'hooks/useCustomNotify';
import ClaimSuccessNotification from './components/ClaimSuccessNotification';
import { FormErrorLabel } from 'components/form';

const useStyles = makeStyles((theme) => ({
  container: {
    gap: '32px',
    flexWrap: 'nowrap',
  },
  submitButton: {
    marginTop: theme.spacing(3),
    textTransform: 'none',
  },
  leftColumn: {
    gap: '24px',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '800px',
  },
  submitError: {
    marginTop: theme.spacing(3),
  },
}));

export interface FormValues {
  '@type': ClaimReason;
  items: Item[];
}

const initialValues: FormValues = {
  '@type': ClaimReason.UPSLost,
  items: [
    {
      description: '',
      quantity: '',
      unitValue: '',
      replaceable: 'false',
      packageType: '',
      sealType: '',
      replacementTrackingNumber: '',
      itemsMissing: 'false',
      dangerousContents: 'NONE',
      sealedSafe: 'false',
      dateDamageWasDiscovered: '',
      currentLocationOfDamagedItem: '',
      damagedPhoto: {
        [ItemPhotoType.Inside]: null,
        [ItemPhotoType.Outside]: null,
      },
      index: 1,
    },
  ],
};

const UPSClaimForm = () => {
  const classes = useStyles();
  const notify = useCustomNotify();

  const dataProvider = useDataProvider();

  const { id } = useParams();
  const history = useHistory();

  const formRef = useRef<FormApi<FormValues>>();

  const [shipment, setShipment] = useState<Rollo.ShipmentDTO>({});

  const getShipment = useCallback(async () => {
    const { data } = await dataProvider.getOne('shipments', { id });

    setShipment(data);
  }, [dataProvider, id]);

  useEffect(() => {
    if (id) {
      getShipment();
    }
  }, [getShipment, id]);

  useEffect(() => {
    if (!shipment.order?.items?.length) {
      return;
    }

    const parsedItems: Item[] = shipment.order.items.map((item, index) => ({
      ...initialValues.items[0],
      description:
        `${item.name ?? ''} ${item.description ?? ''}`.trim() || initialValues.items[0].description,
      unitValue: item.price ? String(item.price) : initialValues.items[0].unitValue,
      purchaseDate: shipment.order?.purchaseDate ?? initialValues.items[0].purchaseDate,
      index: index,
    }));

    formRef.current?.change('items', parsedItems);
  }, [shipment]);

  const generateData = (values: FormValues) => {
    if (values['@type'] === ClaimReason.UPSLost) {
      return {
        '@type': values['@type'],
        items: values.items.map((item) => ({
          description: item.description,
          quantity: item.quantity,
          unitValue: item.unitValue,
          replaceable: JSON.parse(item?.replaceable),
          replacementTrackingNumber: item.replacementTrackingNumber,
          index: item.index,
        })),
      };
    } else {
      return {
        '@type': values['@type'],
        items: values.items.map((item) => ({
          description: item.description,
          quantity: item.quantity,
          unitValue: item.unitValue,
          itemsMissing: JSON.parse(item?.itemsMissing),
          ...(item.dangerousContents !== 'NONE' && {
            dangerousContents: [item.dangerousContents],
          }),
          sealedSafe: JSON.parse(item?.sealedSafe),
          sealType: item.sealType,
          packageType: item.packageType,
          dateDamageWasDiscovered: item.dateDamageWasDiscovered,
          currentLocationOfDamagedItem: item.currentLocationOfDamagedItem,
          customSealType: item.sealType === 'CUSTOM' ? item.customSealType : null,
          customPackageType: item.packageType === 'CUSTOM' ? item.customPackageType : null,
          index: item.index,
        })),
      };
    }
  };

  const handleSubmitForm = async (values) => {
    if (!shipment.id) {
      return;
    }

    try {
      const data = generateData(values);
      await dataProvider.create('createClaim', { data, id: shipment.id });

      notify(<ClaimSuccessNotification account={shipment.account} />, 'claim');

      history.goBack();
    } catch (error) {
      notify(error.message, 'error');
    }
  };

  return (
    <Form<FormValues>
      onSubmit={handleSubmitForm}
      initialValues={initialValues}
      render={({
        submitting,
        handleSubmit,
        errors,
        submitFailed,
        values,
        form,
        ...renderProps
      }) => {
        formRef.current = form;

        return (
          <form onSubmit={handleSubmit}>
            <Grid container className={classes.container}>
              <Grid item xs={7} className={classes.leftColumn}>
                <PageHeader />

                <SectionCard icon={<HelpOutline />} title="Reason of claim">
                  <RadioGroup name="@type" label="">
                    <Radio label="Lost" name="@type" value={ClaimReason.UPSLost} />
                    <Radio label="Damaged" name="@type" value={ClaimReason.UPSDamaged} />
                  </RadioGroup>
                </SectionCard>

                {values['@type'] === ClaimReason.UPSDamaged && (
                  <PackagePhotoSection errors={errors} submitFailed={submitFailed} isUPS />
                )}

                {values['@type'] === ClaimReason.UPSLost && <LostItemSection isUPS />}

                {values['@type'] === ClaimReason.UPSDamaged && <DamagedItemSection isUPS />}
              </Grid>
              <Grid item xs={5}>
                <SummarySection
                  isUPS
                  loading={!shipment?.id}
                  reason={values['@type']}
                  trackingNumber={shipment.trackNumber}
                  trackLink={shipment.trackLink}
                  shippingService={shipment.service?.name}
                  warehouse={shipment.warehouse}
                  customer={shipment.customer}
                  logoUrl={shipment.account?.provider?.logoUrl}
                  items={values.items}
                  packageInfo={shipment.packageInfo}
                />

                <FormErrorLabel
                  {...renderProps}
                  submitFailed={submitFailed}
                  className={classes.submitError}
                  validationErrorText="Validation error. Please check your entries"
                />

                <SubmitButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  label="Submit claim"
                  disabled={submitting}
                  submitting={submitting}
                  className={classes.submitButton}
                />
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

export default UPSClaimForm;
